import { Log } from '@lightningjs/sdk';
import { DvrWindowDuration, PlaybackType, Proposition, StreamingProtocol, VacType, VideoInitiate, } from '@sky-uk-ott/core-video-sdk-js';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { getProxyDrmHmac } from '../../util/drm';
import appVersion from '../../version';
import { getAssetMetadata } from './getCoreVideoSdkMetadata';
import { getPreferredLanguages, getWhiteLabelBrand } from '../../helpers';
import MParticleApi from '../../api/MParticleApi';
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore';
import { isVod } from '../../components/player/helpers/metadata';
import { getCoreVideoSdkPlatform } from './getCoreVideoSdkPlatform';
import { getDRMDeviceName } from './getDRMDeviceName';
import TVPlatform from '../../lib/tv-platform';
import UserInteractionsStoreSingleton from '../../store/UserInteractions';
import { CONTENT_TYPE } from '../../constants';
import { isEpgStream } from '../../store/PlayerStore/actions/epg';
import { ConsentManagement } from '../../lib/cmp/ConsentManagement';
const ProgrammingTypes = {
    'Full Event Replay': PlaybackType.FER,
    'Single Live Event': PlaybackType.SingleLiveEvent,
    'Full Episode': PlaybackType.VOD,
    Clip: PlaybackType.Clip,
    'Web Exclusive': PlaybackType.Clip,
    Movie: PlaybackType.VOD,
    Highlight: PlaybackType.Clip,
    'Short-Form VOD': PlaybackType.Clip,
    Trailer: PlaybackType.Clip,
    Excerpt: PlaybackType.Clip,
    'Sneak Peek': PlaybackType.Clip,
    'Teaser Trailer': PlaybackType.Clip,
    Highlights: PlaybackType.Clip,
};
export const getSessionConfig = async ({ mvpd, isNBCProfileLinked, mvpdHash, mParticleId, program, lemonade, product, stream, raw, geo, }) => {
    const drmConfiguration = getDrmConfiguration(program, geo);
    const deviceAdvertisingTrackingConsent = !TVPlatform.getUserOptOut();
    const percentViewed = (stream === null || stream === void 0 ? void 0 : stream.v4ID)
        ? UserInteractionsStoreSingleton.getPercentViewed(stream === null || stream === void 0 ? void 0 : stream.v4ID)
        : 0;
    const duration = (stream && 'duration' in stream && (stream === null || stream === void 0 ? void 0 : stream.duration)) || 0;
    const startPosition = isVod(program === null || program === void 0 ? void 0 : program.programmingType)
        ? {
            startPosition: percentViewed < 0.95 ? percentViewed * duration : 0,
        }
        : {};
    const { usPrivacy = undefined, gpp, gppSid } = ConsentManagement.getFWParams();
    Log.info('deviceAdvertisingTrackingConsent', deviceAdvertisingTrackingConsent);
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ type: (() => {
            const programmingType = (program === null || program === void 0 ? void 0 : program.programmingType) || '';
            const type = 
            // @ts-expect-error Property 'contentType' does not exist on type
            (stream === null || stream === void 0 ? void 0 : stream.contentType) === CONTENT_TYPE.LINEAR
                ? PlaybackType.Live
                : ProgrammingTypes[programmingType];
            const isLive = (program === null || program === void 0 ? void 0 : program.adobeContentType) === CONTENT_TYPE.LINEAR;
            return !isLive && type ? type : PlaybackType.Live;
        })() }, (raw ? { manifests: [lemonade.playbackUrl] } : {})), { protocol: (DebugControllerSingleton.videoProtocol ||
            (lemonade === null || lemonade === void 0 ? void 0 : lemonade.type) ||
            TVPlatform.streamingProtocol), autoplay: true, muted: false }), startPosition), { key: 'test_stream', vac: {
            deviceAdvertisingTrackingConsent,
            coppaApplies: false,
            mvpdHash,
            mParticleId,
            usPrivacy,
            gpp,
            gppSid,
        }, advertising: {
            contentId: lemonade.adServerContentId ||
                (stream && 'externalAdvertiserId' in stream && (stream === null || stream === void 0 ? void 0 : stream.externalAdvertiserId)) ||
                '',
            adCompatibilityEncodingProfile: lemonade.adCompatibilityEncodingProfile || 'not_required',
        } }), drmConfiguration), { reporting: {
            coppaApplies: false,
            assetMetadata: getAssetMetadata({
                program,
                lemonade,
                mvpd,
                product,
                isNBCProfileLinked,
                stream,
                sessionId: MParticleApi.getSessionId(),
            }),
            videoInitiate: VideoInitiate.Manual,
        }, user: {
            authenticated: mvpd ? true : isNBCProfileLinked ? 'Free' : 'Unauthenticated',
            authenticationProvider: (mvpd === null || mvpd === void 0 ? void 0 : mvpd.mvpd) || 'NBC Profile',
            ids: {
                conviva: {
                    profileid: mParticleId || '',
                },
            },
        }, preferredAudioLanguages: getPreferredLanguages(), sessionRetryConfig: {
            enabled: true,
            cooldownPeriodSecs: 30,
        } }), (lemonade.pvrControls ? { preferredWindowDuration: DvrWindowDuration.Extended } : {}));
};
export const getCoreVideoSdkConfig = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    //TODO: device advertising id and device advertising id type should come from
    // lemonade and not the config
    const debug = DebugControllerSingleton.enabled;
    const appIdentifier = TVPlatform.appIdentifier;
    const { core_video_sdk, adobe, conviva, nielsen, comscore } = (AppConfigFactorySingleton === null || AppConfigFactorySingleton === void 0 ? void 0 : AppConfigFactorySingleton.config) || {};
    // @ts-expect-error TS2352: Conversion of type ...
    return {
        debug,
        proposition: Proposition.OneApp,
        vpi: {
            useCustomVpip: true,
        },
        addons: {
            adInsertion: {
                enabled: true,
                mediaTailorAdInsertionOverrideLinear: true,
                mediaTailorAdInsertionOverrideVod: true,
                mediaTailorAdInsertionOverrideFer: true,
                mediaTailorAdInsertionOverrideSle: true,
                vac: {
                    type: VacType.VAM,
                    url: ((_b = (_a = core_video_sdk === null || core_video_sdk === void 0 ? void 0 : core_video_sdk.vac) === null || _a === void 0 ? void 0 : _a.host) !== null && _b !== void 0 ? _b : 'https://video-ads-module.ad-tech.nbcuni.com') +
                        ((_d = (_c = core_video_sdk === null || core_video_sdk === void 0 ? void 0 : core_video_sdk.vac) === null || _c === void 0 ? void 0 : _c.endpoint) !== null && _d !== void 0 ? _d : '/v1/freewheel-params'),
                    requestData: {
                        appIdentifier,
                        appVersion,
                        appName: 'oneapp',
                        appBrand: getWhiteLabelBrand().name,
                        appBundleId: getWhiteLabelBrand().bundleId,
                        appBuild: appVersion,
                        deviceAdvertisingId: TVPlatform.getAdvertiserId(),
                        deviceAdvertisingIdType: TVPlatform.getDeviceAdvertisingIdType(),
                        platform: getCoreVideoSdkPlatform(),
                    },
                },
                mediaTailor: Object.assign({}, ((_e = core_video_sdk === null || core_video_sdk === void 0 ? void 0 : core_video_sdk.mediaTailor) !== null && _e !== void 0 ? _e : {})),
            },
            common: {
                appIdentifier,
                appName: getWhiteLabelBrand().nielsen,
                appVersion,
                appBrand: getWhiteLabelBrand().app,
                devId: TVPlatform.deviceId,
            },
            reporting: {
                userWatchedAt: new Date(),
                videoInitiate: 'Manual',
                adobe: {
                    enabled: (_f = adobe === null || adobe === void 0 ? void 0 : adobe.enabled) !== null && _f !== void 0 ? _f : true,
                    config: {
                        isDebug: (_g = adobe === null || adobe === void 0 ? void 0 : adobe.debug) !== null && _g !== void 0 ? _g : false,
                        adobeId: (_h = adobe === null || adobe === void 0 ? void 0 : adobe.publisherId) !== null && _h !== void 0 ? _h : '',
                        account: (_j = adobe === null || adobe === void 0 ? void 0 : adobe.rsid) !== null && _j !== void 0 ? _j : '',
                        mediaHeartbeatTrackingServer: (_k = adobe === null || adobe === void 0 ? void 0 : adobe.hbTrackingServer) !== null && _k !== void 0 ? _k : '',
                        analyticsTrackingServer: (_l = adobe === null || adobe === void 0 ? void 0 : adobe.appTrackingServer) !== null && _l !== void 0 ? _l : '',
                        onlineVideoPlatform: (_m = adobe === null || adobe === void 0 ? void 0 : adobe.ovp) !== null && _m !== void 0 ? _m : '',
                        visitorId: (_q = (_p = (_o = window === null || window === void 0 ? void 0 : window.visitor) === null || _o === void 0 ? void 0 : _o.getAnalyticsVisitorID) === null || _p === void 0 ? void 0 : _p.call(_o)) !== null && _q !== void 0 ? _q : '',
                    },
                },
                comscore: {
                    enabled: (_r = comscore === null || comscore === void 0 ? void 0 : comscore.enabled) !== null && _r !== void 0 ? _r : true,
                    config: {
                        customerC2: (_s = comscore === null || comscore === void 0 ? void 0 : comscore.customerC2) !== null && _s !== void 0 ? _s : '6035083',
                        publisherSecret: (_t = comscore === null || comscore === void 0 ? void 0 : comscore.publisherSecret) !== null && _t !== void 0 ? _t : 6035083,
                        allowOfflineTransmission: (_u = comscore === null || comscore === void 0 ? void 0 : comscore.allowOfflineTransmission) !== null && _u !== void 0 ? _u : true,
                    },
                },
                conviva: {
                    enabled: (_v = conviva === null || conviva === void 0 ? void 0 : conviva.enabled) !== null && _v !== void 0 ? _v : true,
                    config: {
                        customerKey: (_w = conviva === null || conviva === void 0 ? void 0 : conviva.api_key) !== null && _w !== void 0 ? _w : '5236d0980748b4d4e0ea4dec01db0a78006ad447',
                        gatewayUrl: (_x = conviva === null || conviva === void 0 ? void 0 : conviva.gatewayUrl) !== null && _x !== void 0 ? _x : 'https://nbcu-central-test.testonly.conviva.com',
                        mutedErrorCodes: [],
                        enableAdInsights: (_y = conviva === null || conviva === void 0 ? void 0 : conviva.enableAdInsights) !== null && _y !== void 0 ? _y : true,
                        shouldUseLegacyLibrary: false,
                        cdn: 'AKAMAI',
                    },
                },
                nielsen: {
                    enabled: (_z = nielsen === null || nielsen === void 0 ? void 0 : nielsen.enabled) !== null && _z !== void 0 ? _z : true,
                    config: {
                        cloudAppId: nielsen === null || nielsen === void 0 ? void 0 : nielsen.id,
                        airdate: new Date(),
                        enabledPlaybackTypes: [
                            PlaybackType.Live,
                            PlaybackType.SingleLiveEvent,
                            PlaybackType.VOD,
                            PlaybackType.FER,
                            PlaybackType.Clip,
                            PlaybackType.Preview,
                        ],
                        enableProduction: (_0 = nielsen === null || nielsen === void 0 ? void 0 : nielsen.enableProduction) !== null && _0 !== void 0 ? _0 : true,
                    },
                },
            },
        },
        players: {
            aamp: {
                enableAsyncTune: true,
            },
        }
    };
};
const getDrmConfiguration = (program, geo) => {
    return {
        drmConfiguration: {
            type: TVPlatform.drmType,
            licenceAcquisitionUrl: buildLicenseAcquisitionUrl(program === null || program === void 0 ? void 0 : program.callSign, geo),
        },
    };
};
const buildLicenseAcquisitionUrl = (callSign, geo) => {
    var _a;
    const { core_video_sdk, colorShader } = (AppConfigFactorySingleton === null || AppConfigFactorySingleton === void 0 ? void 0 : AppConfigFactorySingleton.config) || {};
    const { localizedChannelInfo } = geo || {};
    const keyToPass = (localizedChannelInfo === null || localizedChannelInfo === void 0 ? void 0 : localizedChannelInfo.geoChannel) || (localizedChannelInfo === null || localizedChannelInfo === void 0 ? void 0 : localizedChannelInfo.channel) || callSign;
    callSign = keyToPass ? `&keyId=${encodeURI(keyToPass)}` : '';
    const { hash, time } = getProxyDrmHmac(colorShader, TVPlatform.drmType);
    return `${(_a = core_video_sdk === null || core_video_sdk === void 0 ? void 0 : core_video_sdk.drm) === null || _a === void 0 ? void 0 : _a.licenceUrl}/${TVPlatform.drmType.toLowerCase()}?time=${time}&hash=${hash}&device=${getDRMDeviceName()}${callSign}`;
};
export const getCoreStreamUrl = (lemonade) => DebugControllerSingleton.streamUrl || (lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrl) || (lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrls);
const getStreamingProtocol = (type) => {
    switch (true) {
        case /HLS/.test(type):
            return StreamingProtocol.HLS;
        case /MSS/.test(type):
            return StreamingProtocol.MSS;
        case /PDL/.test(type):
            return StreamingProtocol.PDL;
        case /PSF/.test(type):
            return StreamingProtocol.PSF;
        case /DASH/.test(type):
        default:
            return StreamingProtocol.DASH;
    }
};
export const getPlayoutData = async (vod) => {
    var _a, _b;
    const { stream, program, lemonade, geo } = PlayerStoreSingleton;
    if (!stream || !program || !lemonade)
        return;
    const epgStream = isEpgStream(stream);
    const externalAdvertiserId = 'externalAdvertiserId' in stream ? stream === null || stream === void 0 ? void 0 : stream.externalAdvertiserId : '';
    const mpxGuid = 'mpxGuid' in stream ? stream === null || stream === void 0 ? void 0 : stream.mpxGuid : '';
    const durationInMilliseconds = 'durationInMilliseconds' in program ? program === null || program === void 0 ? void 0 : program.durationInMilliseconds : '';
    const callSign = 'callSign' in program ? program === null || program === void 0 ? void 0 : program.callSign : '';
    const adCompatibilityEncodingProfile = 'adCompatibilityEncodingProfile' in program ? program === null || program === void 0 ? void 0 : program.adCompatibilityEncodingProfile : '';
    const adServerContentId = 'adServerContentId' in program ? program === null || program === void 0 ? void 0 : program.adServerContentId : '';
    const isVOD = 'programmingType' in program && isVod(program === null || program === void 0 ? void 0 : program.programmingType);
    const endpointData = (lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrls) || [
        {
            url: getCoreStreamUrl(lemonade),
            cdn: 'AKAMAI',
        },
    ];
    const livePlayoutData = Object.assign(Object.assign({ statusCode: 200, type: 'content' }, (lemonade
        ? {
            advertising: {
                assetId: lemonade.adServerContentId || !epgStream ? externalAdvertiserId : '',
                contentId: lemonade.adServerContentId || externalAdvertiserId,
            },
        }
        : {})), { ovpData: Object.assign(Object.assign({ nielsenTrackingType: isVOD ||
                !lemonade ||
                ((_a = lemonade.adInstructions) === null || _a === void 0 ? void 0 : _a.dai_MidRollEnabled) ||
                ((_b = lemonade.adInstructions) === null || _b === void 0 ? void 0 : _b.dai_PreRollEnabled)
                ? 'DCR'
                : 'DTVR', asset: {
                endpoints: endpointData,
                format: {
                    protection: TVPlatform.drmType,
                    transport: vod && (lemonade === null || lemonade === void 0 ? void 0 : lemonade.type)
                        ? getStreamingProtocol(lemonade.type)
                        : TVPlatform.streamingProtocol,
                    acodec: 'AAC',
                    vcodec: 'H264',
                    container: 'ISOBMFF',
                },
            }, protection: {
                licenceAcquisitionUrl: buildLicenseAcquisitionUrl(callSign, geo),
                type: TVPlatform.drmType.toUpperCase(),
            }, thirdParties: {
                FREEWHEEL: {
                    adCompatibilityEncodingProfile: adCompatibilityEncodingProfile || 'not_required',
                    contentId: lemonade.adServerContentId || adServerContentId || externalAdvertiserId,
                },
            }, videoFormat: 'UNKNOWN', durationMs: durationInMilliseconds || 0 }, ((lemonade === null || lemonade === void 0 ? void 0 : lemonade.adInstructions)
            ? {
                adInstructions: {
                    //These need to be strings for core video
                    dai_MidRollEnabled: lemonade.adInstructions.dai_MidRollEnabled ? 'true' : 'false',
                    dai_PreRollEnabled: lemonade.adInstructions.dai_PreRollEnabled ? 'true' : 'false',
                },
            }
            : {})), { contentId: mpxGuid }) });
    Log.info('getCoreVideoSdkConfig.js livePlayoutData', livePlayoutData);
    return livePlayoutData;
};
